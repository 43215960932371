<template>
  <b-row>
    <b-col md="12" v-if="institucionCards.length == 0 || isEdit">
      <header class="mt-5 bx-header-title">
        <b-alert show variant="primary" class="text-justify" v-html="$t('busqueda-institucion.texto-ayuda')"></b-alert>
        <h3
          id="busqueda-institucion-title-id"
          v-text="
            tipo == 2
              ? $t('busqueda-institucion.title2', { requiredString: required ? '*' : '' })
              : $t('busqueda-institucion.title', { requiredString: required ? '*' : '' })
          "
          class="inline"
        ></h3>
        <div v-if="!isEdit" class="mt-3 float-right">
          <b-button @click="edit" variant="primary">
            <span v-text="$t('busqueda-institucion.action.add')"></span>
            <span class="icon-mas"></span>
          </b-button>
        </div>
      </header>
    </b-col>
    <b-col cols="12">
      <cards-list
        v-if="institucionCards.length > 0 && !isEdit"
        id="busqueda-institucion-card-id"
        :items="institucionCards"
        :title="tipo == 2 ? $t('busqueda-institucion.titleCard2') : $t('busqueda-institucion.titleCard')"
        @remove="removeInstitucion"
        @edit="prepareBusquedaInstitucion"
        :mostrarListado="false"
      >
      </cards-list>
    </b-col>
    <template v-if="isEdit">
      <b-col sm="12" v-if="!this.setteoManual">
        <input-text
          class="mb-4"
          id="busquedaInstitucion.buscadorInstitucion-id"
          v-model="$v.busquedaInstitucion.buscadorInstitucion.$model"
          :label="$t('busqueda-institucion.buscador-institucion.label')"
          :readonly="false"
          :required="false"
          @change="onTipoChanged($v.busquedaInstitucion.tipoInstitucion.$model.id)"
          :placeholder="placeholderBuscador"
          :valid="$v.busquedaInstitucion.buscadorInstitucion.$dirty ? !$v.busquedaInstitucion.buscadorInstitucion.$error : null"
          :validationsCommons="{
            minValue: !$v.busquedaInstitucion.buscadorInstitucion.minLength,
            minMessage: $t('busqueda-institucion.buscador-institucion.validations.minMessage', { max: '3' }),
            maxValue: !$v.busquedaInstitucion.buscadorInstitucion.maxLength,
            maxMessage: $t('busqueda-institucion.buscador-institucion.validations.maxMessage', { max: '100' }),
          }"
        />
      </b-col>
      <b-col sm="12" v-if="!this.setteoManual">
        <input-select-radio
          class="mb-4"
          id="busquedaInstitucion.tipoInstitucion-id"
          v-model="$v.busquedaInstitucion.tipoInstitucion.$model"
          :label="$t('busqueda-institucion.tipo-institucion.label')"
          :options="catTipoInstitucion"
          :readonly="editingIndex != null"
          :required="true"
          @change="onTipoChanged($event.id)"
          :valid="$v.busquedaInstitucion.tipoInstitucion.$dirty ? !$v.busquedaInstitucion.tipoInstitucion.$error : null"
          :validationsCommons="{
            requiredValue: !$v.busquedaInstitucion.tipoInstitucion.required,
            requiredMessage: $t('busqueda-institucion.tipo-institucion.validations.required'),
          }"
        />
      </b-col>
      <div class="clearfix"></div>
      <b-col v-if="publicPrivadaIf && !this.setteoManual" sm="12" md="4" lg="4">
        <input-select-one
          id="publica-privada-id"
          v-model="$v.busquedaInstitucion.publicaPrivada.$model"
          :label="$t('busqueda-institucion.publica-privada.label')"
          :options="catPublicaPrivada"
          :readonly="false"
          :required="true"
          @change="onPublicaPrivadaChanged($event)"
          :valid="$v.busquedaInstitucion.publicaPrivada.$dirty ? !$v.busquedaInstitucion.publicaPrivada.$error : null"
          :validationsCommons="{
            requiredValue: !$v.busquedaInstitucion.publicaPrivada.required,
            requiredMessage: $t('busqueda-institucion.publica-privada.validations.required'),
          }"
        />
      </b-col>
      <div class="clearfix"></div>
      <b-col v-if="paisIf && !this.setteoManual" sm="12" md="4" lg="4">
        <input-select-one
          id="pais-id"
          v-model="$v.busquedaInstitucion.pais.$model"
          :label="$t('busqueda-institucion.pais.label')"
          :options="catPais"
          :readonly="false"
          :required="true"
          @change="onPaisChanged($event)"
          :valid="$v.busquedaInstitucion.pais.$dirty ? !$v.busquedaInstitucion.pais.$error : null"
          :validationsCommons="{
            requiredValue: !$v.busquedaInstitucion.pais.required,
            requiredMessage: $t('busqueda-institucion.pais.validations.required'),
          }"
        />
      </b-col>
      <div class="clearfix"></div>
      <b-col v-if="entidadIf && !this.setteoManual" sm="12" md="4" lg="4">
        <input-select-one
          id="entidad-id"
          v-model="$v.busquedaInstitucion.entidad.$model"
          :label="$t('busqueda-institucion.entidad.label')"
          :options="catEntidad"
          :readonly="false"
          :required="true"
          @change="onEntidadChanged($event)"
          :valid="$v.busquedaInstitucion.entidad.$dirty ? !$v.busquedaInstitucion.entidad.$error : null"
          :validationsCommons="{
            requiredValue: !$v.busquedaInstitucion.entidad.required,
            requiredMessage: $t('busqueda-institucion.entidad.validations.required'),
          }"
        />
      </b-col>
      <b-col v-if="municipioIf && !this.setteoManual" sm="12" md="4" lg="4">
        <input-select-one
          id="municipio-id"
          v-model="$v.busquedaInstitucion.municipio.$model"
          :label="$t('busqueda-institucion.municipio.label')"
          :options="catMunicipio"
          :readonly="$v.busquedaInstitucion.entidad.$model == null"
          :required="true"
          @change="onMunicipioChanged($event)"
          :valid="$v.busquedaInstitucion.municipio.$dirty ? !$v.busquedaInstitucion.municipio.$error : null"
          :validationsCommons="{
            requiredValue: !$v.busquedaInstitucion.municipio.required,
            requiredMessage: $t('busqueda-institucion.municipio.validations.required'),
          }"
        />
      </b-col>

      <div class="clearfix"></div>
      <b-col v-if="institucionIf && !this.setteoManual" sm="12">
        <input-select-one
          id="institucion-id"
          v-model="$v.busquedaInstitucion.institucion.$model"
          :label="tipo == 2 ? $t('busqueda-institucion.institucion.label2') : $t('busqueda-institucion.institucion.label')"
          :options="catInstitucion"
          :readonly="false"
          :required="true"
          :valid="$v.busquedaInstitucion.institucion.$dirty ? !$v.busquedaInstitucion.institucion.$error : null"
          :validationsCommons="{
            requiredValue: !$v.busquedaInstitucion.institucion.required,
            requiredMessage: $t('busqueda-institucion.institucion.validations.required'),
          }"
        />
      </b-col>

      <div class="clearfix"></div>
      <b-col sm="12" md="6" lg="6" v-if="institucionIf">
        <b-link @click="openModal" v-text="$t('busqueda-institucion.button-modal.mjs')"></b-link>
      </b-col>
      <div class="clearfix"></div>

      <b-col cols="12">
        <div class="float-right mt-3">
          <b-button @click="cancel" variant="outline-primary" size="md">
            <span v-text="$t('busqueda-institucion.action.cancel')"></span>
            <font-awesome-icon icon="ban" class="mr-2"></font-awesome-icon>
          </b-button>
          <b-button @click="addInstitucion($v.busquedaInstitucion.$model)" variant="primary" size="md" :disabled="this.validaDisabled()">
            <span v-if="editingIndex === null" v-text="$t('busqueda-institucion.action.save')"></span>
            <span v-else v-text="$t('busqueda-institucion.action.edit')"></span>
            <font-awesome-icon icon="save" class="mr-2"></font-awesome-icon>
          </b-button>
        </div>
      </b-col>
    </template>
    <template v-if="validInstComponent">
      <b-col>
        <div class="invalid-feedback d-block">
          <span v-text="$t('busqueda-institucion.generalRequired')"></span>
        </div>
      </b-col>
    </template>
    <b-overlay :show="showInst" no-wrap spinner-type="grow" spinner-variant="primary" />
    <template>
      <b-modal id="modalInstitucionAyudaId" :title="$t('busqueda-institucion.modal.title')" size="lg" z-index="2">
        <p><b v-text="$t('busqueda-institucion.modal.p1')"></b></p>
        <p v-text="$t('busqueda-institucion.modal.p2')"></p>
        <p class="indentation20"><b v-text="$t('busqueda-institucion.modal.p3')"></b></p>
        <p v-text="$t('busqueda-institucion.modal.p4')" class="indentation40"></p>
        <p v-text="$t('busqueda-institucion.modal.p5')" class="indentation40"></p>
        <p v-text="$t('busqueda-institucion.modal.p6')" class="indentation40"></p>
        <p v-text="$t('busqueda-institucion.modal.p7')" class="indentation40"></p>
        <p v-text="$t('busqueda-institucion.modal.p8')"></p>
        <p
          v-html="
            $t('busqueda-institucion.modal.p9') +
            ' <a>' +
            $t('busqueda-institucion.modal.p10') +
            '</a>' +
            $t('busqueda-institucion.modal.p11')
          "
        ></p>

        <p></p>
        <p><b v-text="$t('busqueda-institucion.modal.p13')"></b></p>
        <p v-text="$t('busqueda-institucion.modal.p14')" class="indentation20"></p>
        <p class="indentation20"><u v-text="$t('busqueda-institucion.modal.p15')"></u></p>
        <p v-text="$t('busqueda-institucion.modal.p16')" class="indentation20"></p>
        <p v-text="$t('busqueda-institucion.modal.p17')" class="indentation20"></p>
        <p v-text="$t('busqueda-institucion.modal.p18')" class="indentation20"></p>
        <p v-text="$t('busqueda-institucion.modal.p19')" class="indentation40"></p>
        <p v-text="$t('busqueda-institucion.modal.p20')" class="indentation40"></p>
        <p v-text="$t('busqueda-institucion.modal.p21')" class="indentation40"></p>
        <p v-text="$t('busqueda-institucion.modal.p22')" class="indentation40"></p>
        <p v-text="$t('busqueda-institucion.modal.p23')" class="indentation40"></p>
        <p v-text="$t('busqueda-institucion.modal.p24')" class="indentation20"></p>
        <p v-text="$t('busqueda-institucion.modal.p25')" class="indentation20"></p>
        <template #modal-footer="{ cancel }">
          <b-button
            id="documento-probatorio-save-id"
            variant="outline-primary"
            v-text="$t('busqueda-institucion.modal.accept')"
            @click="cancel"
          ></b-button>
        </template>
      </b-modal>
    </template>
  </b-row>
</template>
<script lang="ts" src="./busqueda-institucion.component.ts"></script>
<style>
.text-justify {
  text-align: justify;
}
</style>
