






























.alert-info-card {
  .icon-info {
    font-size: 2.5rem;
  }
}
@media (max-width: 450px) {
  .aic-1 {
    display: block;
    margin: auto;
  }
  .text-center {
    display: contents;
  }
  .col-2 {
    margin: 0 auto 20px auto;
    padding: 0;
  }
  #m-3-1 h3 {
    font-size: 22px;
  }
}
