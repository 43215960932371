

























































































































































.card-body .row .center-felx {
  width: 100px;
  padding-bottom: 15px;
  .btn {
    span {
      display: block;
      border: solid 1px #002663;
      padding: 6px;
      border-radius: 50%;
    }
  }
}
.card-body .row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.center-felx {
  display: flex;
  align-content: space-around;
  justify-content: flex-start;
  align-items: center;
}
.felx-2 {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  padding-left: 1%;
  padding-right: 1%;
}
.left-felx {
  width: 600px;
  .custom-title {
    height: auto;
    max-height: 45px;
    overflow: hidden;
    white-space: initial;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .custom-title[title]:hover::after {
    background: #001a44;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
  }
  p {
    text-align: left;
  }
}
.right-felx {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.col-lg-3 {
  max-width: 100%;
}
.col-lg-8 {
  flex: 0 0 66%;
}

.card-contacto {
  span {
    font-size: 3.1rem !important;
  }
}
.icon-diploma::before,
.icon-libro::before {
  font-size: 60px;
  opacity: 50%;
}
.rt-1 {
  margin: 0em 0 0 auto;
}
.large-labels {
  font-size: 75%;
  font-weight: 700;
  margin-bottom: 16px;
  display: inline-block;
  min-width: 30px;
  padding: 0 10px;
}
.large-labels span {
  display: block;
  padding: 0 10px;
}
.ll-1 {
  margin: 2px 2px;
  color: #fff;
  background-color: #007bff;
  border-radius: 10px;
  display: inline-block;
}
.productoPrincipal {
  padding: 0;
  font-size: 14px;
  text-align: end;
}
@media (max-width: 1162px) {
  .col-lg-8 {
    flex: 0 0 50%;
  }
}

@media (max-width: 1100px) {
  .card-body .row {
    flex-wrap: nowrap;
  }
  .card-body .row .center-felx {
    padding: 0;
    margin-left: 5px;
    margin-right: 10px;
  }
  .center-felx span {
    width: 66px;
    height: 66px;
  }
  .left-felx {
    padding: 0;
  }
  .left-felx p {
    margin: 0 17px 0px 0;
    padding: 0;
  }
  .right-felx {
    padding: 0;
  }
  .felx-2 {
    padding: 0;
  }
  .rt-1 {
    margin: 0em auto 0 0;
  }
}
@media (max-width: 518px) {
  .left-felx .card-text {
    width: 234px;
  }
  .felx-2 {
    display: block;
  }
  .right-felx {
    flex-direction: row-reverse;
  }
  .productoPrincipal {
    text-align: start;
  }
}
