/*
=============================================
Archivo: gobierno-mexico-2024.scss
Descripción: Estilos específicos para la nueva identidad gráfica del Gobierno de México 2024-2030.
Autor: Salomón Flores
Fecha de creación: 11 de diciembre de 2024
Última modificación: 20 de diciembre de 2024
=============================================
*/

/* ========================================
    SECCIÓN: Variables de color
======================================== */
$color-principal: #611232; /* Color principal (usado en enlaces, botones, etc.) */
$color-secundario: #a57f2c; /* Color secundario (usado en encabezados y detalles) */
$btn-ayuda: #6f7271;
$color-gris: #161a1d;

/* ========================================
    SECCIÓN: Estilos para la barra de navegación
======================================== */
.navbar-dark {
  background-color: $color-principal !important; /* Color de fondo de la barra de navegación */
}

/* ========================================
    SECCIÓN: Estilos para el encabezado
======================================== */
.header-conacyt-sesion,
.header-conacyt {
  background-color: $color-secundario !important; /* Color de fondo del encabezado */
}

/* ========================================
    SECCIÓN: Estilos para enlaces (a)
======================================== */
a {
  color: $color-principal !important; /* Color de los enlaces en estado normal */
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: $color-secundario !important; /* Color de los enlaces cuando el mouse pasa sobre ellos */
}

/* ========================================
    SECCIÓN: Estilos para botones primarios
======================================== */
.btn-primary {
  color: $color-principal !important;
  background-color: #fff !important;
  border-color: $color-principal !important;
  border: 2px solid $color-principal !important;
  box-shadow: none !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: $color-principal !important;
  border-color: $color-principal !important;
  box-shadow: none !important;
}

.btn-primary:focus {
  color: #fff !important;
  background-color: $color-principal !important;
  border-color: $color-principal !important;
  box-shadow: none !important;
}

/* ========================================
    SECCIÓN: Estilos para el sidebar (barra lateral)
======================================== */
#sidebar-wrapper .sidebar_header a {
  color: $color-principal !important; /* Color de los enlaces en la cabecera del sidebar */
}

.sidebar .nav-link,
.sidebar .dropdown-item {
  color: $color-gris !important; /* Color de los enlaces y elementos del sidebar */
}

#sidebar-wrapper {
  border-right: 2px solid $color-principal !important; /* Borde derecho del sidebar */
}

/* ========================================
    SECCIÓN: Estilos para elementos activos en el sidebar
======================================== */
.sidebar .nav-item .active,
.sidebar .nav-item .router-link-exact-active {
  background-color: $color-principal !important; /* Fondo para elementos activos */
  border-left: 4px solid $color-secundario !important; /* Borde izquierdo para elementos activos */
}

/* ========================================
    SECCIÓN: Estilos para los subelementos del sidebar
======================================== */
ul.list-unstyled.subitems {
  border-right: 6px solid $color-secundario !important; /* Borde derecho para subelementos */
}

.sidebar .subitems li .dropdown-item.active,
.sidebar .subitems li .dropdown-item:active {
  background-color: $color-principal !important; /* Fondo para elementos activos en subelementos */
  border-left: 4px solid $color-secundario !important; /* Borde izquierdo para elementos activos en subelementos */
}

.sidebar .nav-link:hover,
.sidebar .dropdown-item:hover {
  background-color: $color-principal !important;
  border-left: 4px solid $color-secundario !important;
}

/* ========================================
    SECCIÓN: Estilos para botones de tipo outline
======================================== */
.btn-outline-primary {
  color: $btn-ayuda !important; /* Color del texto para botones outline */
  border-color: $btn-ayuda !important; /* Borde de botones outline */
  border: 1px solid $btn-ayuda !important;
  box-shadow: 0 0 0 0 $btn-ayuda !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: $btn-ayuda !important;
  border-color: $btn-ayuda !important;
  box-shadow: 0 0 0 0 $btn-ayuda !important;
}

.btn-outline-primary:active {
  color: #fff !important;
  background-color: $btn-ayuda !important;
  border-color: $btn-ayuda !important;
  box-shadow: 0 0 0 0 $btn-ayuda !important;
}

.btn-blue {
  color: $btn-ayuda !important; /* Color de texto para botones "azul" */
  border-color: $btn-ayuda !important; /* Borde de botones "azul" */
  border: 1px solid $btn-ayuda !important;
  box-shadow: 0 0 0 0 $btn-ayuda !important;
}

/* Estilo para el estado hover */
.btn-blue:hover {
  color: #fff !important; /* Cambiar el color del texto al pasar el cursor */
  background-color: $btn-ayuda; /* Cambiar el color de fondo */
  border-color: $btn-ayuda; /* Cambiar el color del borde */
}

/* ========================================
    SECCIÓN: Estilos para modales
======================================== */
.modal-content .modal-header {
  background: $color-gris !important; /* Fondo del encabezado del modal */
}

/* ========================================
    SECCIÓN: Estilos para elementos personalizados
======================================== */
.custom-dropdown-item::before {
  background: $color-gris !important; /* Fondo antes de los elementos de dropdown */
}

.custom-dropdown-item::after {
  border-left: 1px solid $color-gris !important; /* Borde izquierdo para elementos de dropdown */
}

/* ========================================
    SECCIÓN: Estilos para el componente de animación (la-ball-grid-beat)
======================================== */
.la-ball-grid-beat.la-3x {
  color: $color-principal !important; /* Color de la animación */
}

/* ========================================
    SECCIÓN: Estilos adicionales y específicos
======================================== */
.breadcrumb {
  background-color: #f4f4f4 !important;
}

.breadcrumb-item,
.breadcrumb-item.active {
  color: $color-gris !important;
}

.breadcrumb {
  box-shadow: none !important;
}

h2 {
  color: $color-gris !important;
  font-weight: 600 !important;
}

.navbar-brand.logo.router-link-active {
  text-align: center !important;
}

.badge-secondary {
  background-color: transparent !important;
}

.sidebar .subitems-2 li .dropdown-item:focus {
  background-color: $color-principal !important;
}

#sidebar-wrapper .sidebar_header a {
  color: $color-gris !important;
}

.header-conacyt .user_cvu span {
  font-size: 0.9rem !important;
}

.container-f .drop-wrapper .btn.rounded-0 {
  background-color: $color-principal !important;
}

.sidebar .subitems li .dropdown-item:focus {
  background-color: $color-principal !important;
}

/* ========================================
    SECCIÓN: Fuente global
======================================== */
* {
  font-family: 'Noto Sans', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Noto Sans', sans-serif !important;
}

.btn {
  transition: none !important;
}

.btn:hover,
.btn:focus,
.btn:active {
  transition: none !important;
}
