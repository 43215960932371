































.user-info_ {
  display: flex;
  padding: 0.3rem 0.8rem;
  min-width: 260px;
  &.text {
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  &.footer- {
    flex-direction: column;
  }
}
.divider {
  border-top: 1px solid #e9ecef;
  margin: 0.35rem 0;
}
