



























































































.header-conacyt {
  &.rizoma {
    height: 83px !important;
    margin-bottom: 3rem !important;
  }
}
.plr-50 {
  padding: 0 50px;
}
.chat-popup {
  position: fixed;
  bottom: 5.5rem;
  right: 60px;
  width: 75rem;
  z-index: 9;
}
.floating-btn {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
  position: fixed;
  right: 2.5rem;
  bottom: 2.5rem;
  z-index: 100;
  span {
    &:last-child {
      font-size: 1.8rem;
      margin-top: 2px;
    }
  }
}
