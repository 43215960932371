


























































































[class^='icon-'],
[class*=' icon-'] {
  font-size: 1.25rem !important;
}
.icon-usuario-img {
  width: 35px;
  height: 35px;
  vertical-align: middle;
}
.text-justify {
  text-align: justify;
}
