//---------------------------------------------------------------------------------------
// New variables and overridden variables from Bootstrap.
// check out the variables.scss file in bootstrap for additional ones that are available.
//---------------------------------------------------------------------------------------

// Typography
$font-family-sans-serif: 'Montserrat', sans-serif !important;
$font-size-root: 16px !important;

$white: #ffffff;
$text-color: #161616;

//Primario
$azul-real-oscuro: #002663;
$verde-claro: #7bf17c;

//Elementos de la interfaz
$blanco: #fff;
$gris-10: #f4f4f4;
$gris-20: #e0e0e0;
$gris-50: #8d8d8d;
$gris-100: #161616;

//Texto
$gris-40: #a8a8a8;
$gris-60: #6f6f6f;
$gris-80: #393939;

//Secundaria
$azul-al: #d9edf7;
$azul-cg: #31708f;
$azul-ox: #001a44;
$azul-onu: #4589ff;

// Apoyo
$ambar: #ffc107;
$ambar-obs: #635300cc;
$ambar-pastel: #fff8cc;
$bermellon: #e50000;
$bermellon-obs: #630000cc;
$bermellon-pastel: #ffcccc;
$verde: #00ba2a;
$verde-obs: #005e15cc;
$verde-pastel: #c8ffd4;
$disabled-bg: #f6f6f6;
$disabled-dorder: #f6f6f6;

// Changing the body color
$body-color: $gris-10;
// Changing the headings colour
$headings-color: $gris-80;

// Changing the theme colors
// PRIMARY
$primary: #1553b7;
$primary-border: #4589ff;
$primary-text: #002f81cc;
$primary-bg: #d9edf7;
//DANGER
$danger: $bermellon;
$danger-bg: $bermellon-pastel;
$danger-text: $bermellon-obs;
//SUCCESS
$succes: $verde;
$succes-text: $verde-obs;
$succes-bg: $verde-pastel;
//WARNING
$warning: $ambar;
$warning-text: $ambar-obs;
$warning-bg: $ambar-pastel;

$secondary: #cce4f8;
$info: #4d92df;
// $headings-color: #001538;
$black-141414: $gris-100;
$navbar-top: #001a44;
$outilne-primary: #7c8faf;
$outilne-primary-text: $gris-80;
$border-perfil-usuario-sidebar: #011d4a;
$exito: $verde;

$blue-conacyt: $azul-ox;
$gray-f8: #f8f8f8;
$gray-100: #f4f4f4;
$gray-200: #f2f2f2;
$gray-300: #d4d4d4;
$gray-400: #5a5a5a;
$indigo: #4ba3ff;
$green: $succes;
$link-color: $primary;

// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
$theme-colors: (
  'green-gob': #0b231e,
  'green-v': #0b631e,
);

$theme-fonts: (
  'font-size': 16px,
);

$input-border-width: 2rem;

// Links
$link-color: theme-color('primary');
$link-decoration: underline !default;
$link-hover-decoration: underline !default;

// Buttons
$btn-border-width: 2px;

@mixin b-shadow {
  box-shadow: 0 0.145rem 0.35rem rgba(49, 112, 143, 0.3);
  -webkit-box-shadow: 0 0.145rem 0.35rem rgba(49, 112, 143, 0.3);
  -moz-box-shadow: 0 0.145rem 0.35rem rgba(49, 112, 143, 0.3);
}

@mixin bb-transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}

@mixin all-transition {
  transition: all 0.5s ease;
}

@keyframes fadeInF {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInF {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInF {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeInF {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeInF {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin fadeIn {
  animation: fadeInF linear 0.6s;
  -webkit-animation: fadeInF linear 0.6s;
  -moz-animation: fadeInF linear 0.6s;
  -o-animation: fadeInF linear 0.6s;
  -ms-animation: fadeInF linear 0.6s;
}
