//---------------------------------------------------------------------------------------
// Additions are for extra classes not found in the normal Bootstrap
// WARNING: Do not create alot of new things here, remember always try to tweak and variable
// or override bootstrap css before trying to create something new.
//---------------------------------------------------------------------------------------
// sass-lint:disable force-element-nesting, force-pseudo-nesting

/*
 * RIZOMA CONACYT
 * --------------------------------------------------
*/

.header-title-line {
  position: absolute;
  background-color: $primary;
  bottom: -0.625rem;
  height: 0.313rem;
  width: 2.5rem;
}
/*
   * NAVBAR
   * --------------------------------------------------
  */

a {
  color: $azul-onu !important;

  .dropdown-item {
    text-decoration: none;
  }
  &.btn-primary,
  &.badge-primary {
    color: $white !important;
  }
}

main {
  padding-top: 6rem;
  padding-bottom: 12rem;
  /* Footer height */
}

/*
   * LOGO RIZOMA
   * --------------------------------------------------
  */

@media (min-width: 1198px) {
  .logo-rizoma {
    display: none;
  }
}

// Button icon
.btn-icon {
  height: 2.5rem;
  padding: 0.5rem !important;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}

.btn-round {
  background-color: transparent;
  border: 0;
  border-radius: 50% !important;
  display: inline-block;
}

.btn-icon:hover,
.btn-icon:active {
  background-color: $secondary;
}

.btn-icon .icon {
  color: $primary !important;
}

.btn-icon.disabled,
.btn-icon:disabled,
.btn-icon:disabled:hover,
.btn-icon:disabled:focus,
.btn-icon.disabled:hover,
.btn-icon.disabled:focus {
  cursor: not-allowed;
  background-color: transparent;
  opacity: 0.65;
}

.bx-btn-icn {
  display: inline-flex;
}

/*
   * NAVBAR RESPONSIVE
   * --------------------------------------------------
  */

.nav-collapse-2 {
  width: 100%;
  display: flex;
}
.navbar-right {
  margin-left: auto;
}

button {
  border: 0px;
  outline: none;
}

@media (max-width: 1198px) {
  #nav-collapse {
    margin: 40px 40px;
  }
  .nav-collapse-2 {
    margin: 0 auto;
    justify-content: space-around;
  }

  .ml-auto {
    display: none;
  }
}
@media (max-width: 550px) {
  #nav-collapse {
    align-content: center;
    text-align: center;
    margin-bottom: 50px;
    display: block;
  }
  .nav-collapse-2 {
    display: block;
  }
  .navbar-nav {
    text-align: center;
  }
  .navbar-right {
    margin-left: 0px;
  }
}

/*
  * BUTTON +ADD
  * --------------------------------------------------
  */

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, 0.076, 1)) {
  transition: $property $duration $ease;
}

.btn-add-item {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: rgba(126, 78, 92, 0.1);
  padding: 0;
  font-size: inherit;
  font-family: inherit;

  &.btn-plus {
    width: 100%;
    height: auto;

    .bx-icon {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: relative;
      display: block;
      margin: 0;
      width: 3rem;
      height: 3rem;
      background: $secondary;
      border-radius: 0.25rem;

      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
        color: white;
        position: absolute;
        background: $primary;
        width: 3rem;
        height: 3rem;
        top: 0;
        left: 0;
      }
    }

    .btn-text {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 2.25rem;
      margin: 0 0 0 1.85rem;
      color: $primary !important;
      font-weight: 700;
      line-height: 1.6;
      text-align: left;
    }

    .plus {
      position: absolute;
      top: 14px;
      left: 15px;
    }
  }

  &:hover {
    .bx-icon {
      width: 100%;
    }

    .btn-text {
      padding: 0.75rem 2.5rem;
    }
  }
}

/*
  * HEADER CONACYT
  * --------------------------------------------------
  */
.header-conacyt-sesion {
  height: 200px;
  background-color: $blue-conacyt;
  background-repeat: no-repeat;
  background-position: center bottom;
  margin-bottom: 8rem;
  padding: 15px;
  h1 {
    color: $white !important;
    small {
      display: block !important;
      font-size: 30% !important;
      opacity: 0.8;
    }
  }
  .jumbotron {
    background-color: $white;
    min-height: 135px;
    padding: 0.5rem 0.8rem !important;
    @include b-shadow;
    display: flex;
    align-items: center;

    .container {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    .medium {
      font-size: 18px;
    }
  }
}
.header-conacyt {
  h1 {
    color: $white !important;
    margin-bottom: 20px;
    font-size: 32px;

    small {
      display: block !important;
      font-size: 50% !important;
      opacity: 0.8;
      margin: 10px 0;
    }
  }

  .navbar-light {
    .navbar-nav {
      justify-content: flex-end;

      .dropdown-item {
        display: flex;
        align-items: center;
        color: #121212 !important;

        span {
          padding: 0 1rem !important;
        }

        &:hover {
          background-color: $secondary !important;
        }

        &:focus {
          background-color: $secondary;
          color: #212529 !important;
        }
      }

      .navbar-nav {
        flex-direction: row;
      }

      .nav-link {
        color: $secondary !important;
        @include all-transition;

        &:hover {
          color: $white !important;
        }

        span {
          padding-left: 0.5rem;
          padding-right: 0.1rem;
        }
      }
    }
  }

  .count__box {
    padding: 0 35px;

    &:first-child {
      &::before {
        border-left: 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      height: 65px;
      border-left: 2px solid $gray-300; //d4;
      margin-top: 10px;
    }

    .count__number {
      font-size: 60px;
      line-height: 50px;
      font-weight: 300;
      color: $blue-conacyt;
      @include all-transition;
    }

    .count__title {
      text-transform: uppercase;
      font-size: 16px;
      margin-top: 10px;
      color: #121212 !important;
      @include all-transition;
    }

    @media (max-width: 576px) {
      .count__number {
        font-size: 40px;
        line-height: 20px;
        font-weight: 300;
        color: $blue-conacyt;
      }

      .count__title {
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 10px;
        color: #121212 !important;
      }
    }
  }
}

@media (max-width: 1000px) {
  .header-conacyt .col-7 {
    max-width: 100%;
  }
  .header-conacyt .col-5 {
    max-width: 100%;
    text-align: left;
  }
  .header-conacyt span {
    width: auto;
  }
  .plr-50[data-v-466d78be] {
    padding: 50px 25px 0 25px;
  }
  .header-conacyt .row .col-11 {
    padding-right: 0px;
    max-width: 86%;
  }
  .header-conacyt .row .col-11 .progress {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .bx-header-title h3 {
    font-size: 1.3rem;
    display: flex;
  }
}

@media (max-width: 484px) {
  .header-conacyt .row .col-11 {
    padding-right: 0px;
    max-width: 80%;
  }
  .header-conacyt .jumbotron p {
    font-size: 14px;
    font-weight: medium;
  }
  .header-conacyt .jumbotron .medium {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }

  .header-conacyt .jumbotron .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .col-md-12 .mt-4 {
    font-size: 1.5rem;
  }
}
#app {
  position: relative;
  min-height: 100vh;
  padding-left: 50px;
  @include all-transition;
}
#app.toggled #sidebar-wrapper {
  left: -250px;
  margin-left: 250px;
  width: 250px;
}
#page-content-wrapper {
  width: 100%;
}

/* Sidebar Styles */
@media (prefers-reduced-motion: reduce) {
  .sidebar {
    .sidebar__toggled {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
}
#app.toggled {
  padding-left: 0px;

  #page-content-wrapper {
    position: absolute;
    margin-right: -50px;
  }

  .sidebar__toggled {
    position: relative;
    top: 0;
    right: 0;
    margin-right: 0;
  }
}
.dropdown-toggle {
  padding-right: 20px;
}

@media (min-width: 768px) {
  #app {
    padding-left: 350px;
  }
  #sidebar-wrapper {
    width: 350px;

    .hide-toggle {
      @include fadeIn;
    }
  }

  #app.toggled #sidebar-wrapper {
    left: 0px;
    margin-left: 0;
    width: 0px !important;

    .hide-toggle {
      display: none;
    }

    .sidebar_grouper {
      display: none;
    }
  }
  #app.toggled #page-content-wrapper {
    position: relative;
    margin-right: 50px;
    @include all-transition;
  }
}
//menor a 768px sidebar
@media screen and (max-width: 768px) {
  #sidebar-wrapper {
    width: 50px !important;

    .sidebar__toggled,
    .sidebar_grouper {
      display: none;
    }

    .hide-toggle {
      @include fadeIn;
    }

    &:hover {
      width: 300px !important;
    }
  }
  #app.toggled {
    padding-left: 50px;
  }
}
.card {
  .card-header {
    background-color: $white;
    border: none;
  }
}
.step-indicator {
  margin: 60px 0px 30px 0;
  display: flex;
  align-items: center;
  padding: 0 10px;
  span {
    display: contents;

    &:last-child {
      div {
        &:last-child {
          display: none;
        }
      }
    }
  }
  .step {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    p {
      text-align: center;
      position: absolute;
      top: -35px;
      color: $primary !important;
      font-size: 14px;
      font-weight: bold;
    }
    &.completed .step-icon {
      background: $blue-conacyt;

      &:hover {
        background: $blue-conacyt;
      }
    }
    &.active {
      p {
        color: $blue-conacyt !important;
      }

      .step-icon {
        background: $blue-conacyt;
      }
    }
  }
  .step-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: $primary;
    text-align: center;
    position: relative;
    line-height: 50px;
    font-size: 20px;
    cursor: pointer;
    color: $white !important;
    @include all-transition;
    &:hover {
      background: $blue-conacyt;
      color: $white !important;
    }
  }
}
.indicator-line {
  width: 100%;
  height: 2px;
  background: $primary;
  flex: 1;
  &.active {
    background: $blue-conacyt;
  }
  &.completed {
    background: $blue-conacyt;
  }
}
@media screen and (max-width: 500px) {
  .step p {
    font-size: 11px;
    bottom: -20px;
  }
}
/*
   * FOOTER
   * --------------------------------------------------
   */
/* Footer at the bottom */
footer {
  clear: both;
}
.footer {
  padding: 0.57rem 1rem;
  border-top: 1px solid $gris-80;
  margin-top: 7rem;
  text-align: center;

  .row {
    min-height: 4rem;
  }

  p {
    margin: 0px !important;
  }
}

@media (max-width: 550px) {
  #footer-2 {
    display: block;
    width: auto;
    margin: 0 auto;
  }
  #footer-2 .col-4 {
    margin: 10px auto;
    width: 100%;
    display: contents;
  }
  #footer-2 .col-4 p {
    width: 100%;
    margin: 10px auto;
  }
  #footer-2 .col-4 a {
    display: block;
    margin: 10px auto;
  }
}
@media (max-width: 1000px) {
  .footer {
    margin-bottom: 100px;
  }
}
.popover {
  .popover-header {
    border-radius: calc(0.3rem - 1px);
  }

  .popover-body {
    padding: 0.3rem;

    ul {
      &.subitemsPopover {
        margin-bottom: 0 !important;

        .dropdown-item {
          padding: 0.25rem 0.5rem !important;
          color: #121212 !important;
          @include all-transition;

          &:hover {
            background-color: $secondary;
          }

          &:focus {
            background-color: $secondary;
            color: #212529 !important;
          }
        }

        .active {
          background-color: $secondary !important;
        }
      }
    }
  }
}

.ribbon {
  a {
    color: $white !important;
  }
}

// Mixin itself
@mixin clearfix() {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

// Usage as a mixin
.element {
  @include clearfix;
}
.input-group {
  .vdpComponent {
    flex: 1 1 auto;
    width: 1%;
    input[type='text'] {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: $gris-50;
      background-color: #fff;
      background-clip: padding-box;
      border: 2px solid $azul-real-oscuro;
      border-width: 2px 2px 2px 0px !important;
      border-top-right-radius: 1.15rem;
      border-bottom-right-radius: 1.15rem;
      -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    }
    input:read-only {
      background-color: $gris-10;
      border-color: $gris-50 !important;
    }
    input:focus {
      outline: none !important;
      background-color: $azul-al;
    }
  }
}
.is-invalid {
  .vdpComponent {
    input {
      border-color: $danger !important;
      color: $gris-100;
      background-color: white;
    }
    input:read-only {
      border-color: $danger !important;
      &:hover {
        .input-group-text {
          border: solid $danger !important;
        }
      }
    }
  }
}
.is-valid {
  .vdpComponent {
    input {
      border-color: $succes !important;
      color: $gris-100 !important;
    }
    input:read-only {
      border-color: $succes !important;
      &:hover {
        .input-group-text {
          border: solid $succes !important;
        }
      }
    }
  }
}
//redimenciones
.border-custom {
  border-top: 1px solid $gris-80 !important;
  margin: 2.5rem 0 3.125rem !important;
}
.helper-text {
  font-size: 0.8rem !important;
  line-height: 1.125rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.04em !important;
  color: $gris-80;
}
// Tooltip title cards
[data-tooltip]:before {
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;
  top: 0;
  left: 30%;
  transform: translateX(-30%);
  transition: 256ms all ease;
  padding: 10px 25px;
  color: #333;
  border-radius: 5px;
  box-shadow: 0px 6px 21px rgb(0 0 0 / 10%);
}
[data-tooltip]:hover:before {
  opacity: 1;
  background: $azul-ox;
  color: $white;
  border-radius: 5px;
  top: -40px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1.125rem;
}
