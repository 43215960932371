<template>
  <b-breadcrumb :class="headerClasses">
    <b-breadcrumb-item
      v-for="(item, index) in breadcrumbs"
      :key="index"
      :to="item.link ? item.link : undefined"
      :active="index !== breadcrumbs.length - 1"
    >
      {{ item.name }}
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script lang="ts" src="./breadcrumb.component.ts"></script>

<style scoped>
.breadcrumb {
  margin-bottom: 1rem;
  position: fixed;
  top: 131px;
  left: 350px;
  right: 0;
  z-index: 999;
  padding: 10px 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.breadcrumb-adelgazar {
  top: 106px;
}
</style>
