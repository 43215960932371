//---------------------------------------------------------------------------------------
// Additions are for extra classes not found in the normal Bootstrap
// WARNING: Do not create alot of new things here, remember always try to tweak and variable
// or override bootstrap css before trying to create something new.
//---------------------------------------------------------------------------------------
// sass-lint:disable force-element-nesting, force-pseudo-nesting

/*
 * RIZOMA CONACYT
 * --------------------------------------------------
*/

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $gris-100;
  letter-spacing: 0px !important;
}
h1 {
  font-size: 3.375rem !important;
  line-height: 4rem !important;
  font-weight: 300 !important;
}
h2 {
  color: $blue-conacyt;
  font-size: 2.625rem !important;
  line-height: 3.125rem !important;
  font-weight: 300 !important;
}
h3 {
  color: $blue-conacyt;
  font-size: 2rem !important;
  line-height: 2.5rem !important;
  font-weight: 400 !important;
}
h4 {
  font-size: 1.25rem !important;
  line-height: 2.25rem !important;
  font-weight: 400 !important;
}
h5 {
  font-size: 1.25rem !important;
  line-height: 1.625rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3em !important;
}
h6 {
  font-size: 1rem !important;
  line-height: 1.375rem !important;
  font-weight: 600 !important;
  letter-spacing: 0.3em;
  text-transform: uppercase;
}
.bx-header-title {
  position: relative;
}
//para h2
.bx-header-title,
.mt-5.bx-header-title,
h2.title.mt-4 {
  margin-top: 4.375rem !important;
  margin-bottom: 3.125rem !important;
}
.header-title-line {
  position: absolute;
  background-color: $primary;
  bottom: -0.625rem;
  height: 0.313rem;
  width: 2.5rem;
}
/*pleca*/
.border-custom-dotted {
  border-top: 1px dotted $gris-100 !important;
  margin: 2.5rem 0 3.125rem !important;
}
.row.border-custom.pt-3 {
  margin: 0rem -1.25rem 0rem !important;
}
/*Espacios en botones, caja de botones*/

.mt-3.float-right,
.mb-5.float-right {
  margin: 0px !important;
}
/*
   * NAVBAR
   * --------------------------------------------------
  */
main {
  padding-top: 6rem;
  padding-bottom: 12rem;
  /* Footer height */
}
// Button icon
.btn-icon {
  height: 2.5rem;
  padding: 0.5rem !important;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}
.btn-round {
  background-color: transparent;
  border: 0;
  border-radius: 50% !important;
  display: inline-block;
}
.btn-icon:hover,
.btn-icon:active {
  background-color: $secondary;
}
.btn-icon .icon {
  color: $primary !important;
}
.btn-icon.disabled,
.btn-icon:disabled,
.btn-icon:disabled:hover,
.btn-icon:disabled:focus,
.btn-icon.disabled:hover,
.btn-icon.disabled:focus {
  cursor: not-allowed;
  background-color: transparent;
  opacity: 0.65;
}
.bx-btn-icn {
  display: inline-flex;
}
/*
  * BUTTON +ADD
  * --------------------------------------------------
  */
@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, 0.076, 1)) {
  transition: $property $duration $ease;
}

.btn-add-item {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: rgba(126, 78, 92, 0.1);
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  &.btn-plus {
    width: 100%;
    height: auto;
    .bx-icon {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: relative;
      display: block;
      margin: 0;
      width: 3rem;
      height: 3rem;
      background: $secondary;
      border-radius: 0.25rem;
      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
        color: white;
        position: absolute;
        background: $primary;
        width: 3rem;
        height: 3rem;
        top: 0;
        left: 0;
      }
    }
    .btn-text {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 2.25rem;
      margin: 0 0 0 1.85rem;
      color: $primary !important;
      font-weight: 700;
      line-height: 1.6;
      text-align: left;
    }
    .plus {
      position: absolute;
      top: 14px;
      left: 15px;
    }
  }
  &:hover {
    .bx-icon {
      width: 100%;
    }

    .btn-text {
      padding: 0.75rem 2.5rem;
    }
  }
}
.header-conacyt {
  height: 200px;
  background-color: $blue-conacyt;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 8px 16px;
  .avatar {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: $white;
    display: flex;
    align-items: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    .avatar_img {
      margin: 0 15px 0px 15px;
    }
  }
  .user_cvu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    span {
      display: inline-flex;
    }
  }
  .user {
    color: $white !important;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    small {
      display: block !important;
      font-size: 18px !important;
      margin: 5px 0;
    }
  }
  .navbar-light {
    .navbar-nav {
      justify-content: flex-end;
      .dropdown-item {
        display: flex;
        align-items: center;
        color: #121212 !important;
        span {
          padding: 0 1rem !important;
        }
        &:hover {
          background-color: $primary !important;
        }
        &:focus {
          background-color: $blue-conacyt;
          color: $white !important;
        }
      }
      .navbar-nav {
        flex-direction: row;
      }
      .nav-link {
        color: $primary !important;
        @include all-transition;
        &:hover {
          color: $white !important;
        }
        span {
          padding-left: 0.5rem;
          padding-right: 0.1rem;
        }
      }
    }
  }

  .jumbotron {
    background-color: $white;
    min-height: 135px;
    padding: 0.5rem 0.8rem !important;
    @include b-shadow;
    display: flex;
    align-items: center;
    .container {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .medium {
      font-size: 18px;
    }
  }
  .count__box {
    padding: 0 35px;

    &:first-child {
      &::before {
        border-left: 0;
      }
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      height: 65px;
      border-left: 2px solid $gray-300; //d4;
      margin-top: 10px;
    }

    .count__number {
      font-size: 60px;
      line-height: 50px;
      font-weight: 300;
      color: $blue-conacyt;
      @include all-transition;
    }
    .count__title {
      text-transform: uppercase;
      font-size: 16px;
      margin-top: 10px;
      color: #121212 !important;
      @include all-transition;
    }
    @media (max-width: 576px) {
      .count__number {
        font-size: 40px;
        line-height: 20px;
        font-weight: 300;
        color: $blue-conacyt;
      }
      .count__title {
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 10px;
        color: #121212 !important;
      }
    }
  }
}

//Sidebar
.sidebar {
  .nav-link,
  .dropdown-item {
    width: 100% !important;
    padding: 0.5rem 0.8rem !important;
    color: $gris-100 !important;
    font-weight: 600;
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    &:hover {
      background-color: $primary !important;
      border-left: 4px solid $exito !important;
      color: $white !important;
      &:before {
        width: 100%;
      }
    }
    a {
      text-decoration: none !important;
    }
  }

  .nav-item {
    .active,
    .router-link-exact-active {
      background-color: $blue-conacyt !important;
      border-left: 4px solid $exito !important;
      color: $white !important;
    }
  }
  ul {
    &.navbar-nav {
      padding: 0;
      margin: 0;
    }
  }
  & .navbar-nav {
    & .nav-item {
      font-weight: 600;
      padding-left: 16px;
    }
  }
  .sidebar__toggled {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 2px;
    text-align: center;
    @include all-transition;

    svg {
      font-size: 25px;
    }

    button {
      background: $gray-200;
      border-color: transparent;
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      &:hover {
        background: $secondary !important;
        border-color: transparent !important;
      }
      &:focus {
        border-color: transparent !important;
      }
    }
  }
  .nav-link {
    .item-text {
      padding-left: 12px;
    }
  }
  .subitems {
    li {
      .dropdown-item {
        padding: 0.25rem 0.8rem 0.25rem 2.9rem !important;
        &:hover {
          background-color: $azul-real-oscuro;
          color: $white !important;
        }
        &:focus {
          background-color: $azul-real-oscuro;
          color: $white !important;
        }
        &.active,
        &:active {
          background-color: $azul-real-oscuro !important;
          border-left: 4px solid $verde-claro !important;
          color: $white !important;
        }
      }
    }
  }
  .subitems-2 {
    padding-left: 36px;

    li {
      .dropdown-item {
        padding: 0.25rem 0.8rem 0.25rem 3.9rem !important;

        &:hover {
          background-color: $blue-conacyt;
          color: $white !important;
        }
        &:focus {
          background-color: $blue-conacyt;
          color: $white !important;
        }
        &.active,
        &:active {
          background-color: $blue-conacyt !important;
          border-left: 4px solid $exito !important;
          color: $white !important;
        }
      }
    }
  }
  .subitems-3 {
    padding-left: 36px;

    li {
      .dropdown-item {
        padding: 0.25rem 0.8rem 0.25rem 4.9rem !important;

        &:hover {
          background-color: $blue-conacyt;
          color: $white !important;
        }
        &:focus {
          background-color: $blue-conacyt;
          color: $white !important;
        }
        &.active,
        &:active {
          background-color: $blue-conacyt !important;
          border-left: 4px solid $exito !important;
          color: $white !important;
        }
      }
      &.active {
        background-color: $secondary !important;

        .dropdown-item {
          border-left: 4px solid $blue-conacyt !important;
        }
      }
    }
  }
}
#app {
  position: relative;
  min-height: 100vh;
  padding-left: 50px;
  @include all-transition;
}
#app.toggled {
  padding-left: 300px;
}
#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  top: 56px;
  left: 0px;
  width: 50px;
  height: 95%;
  background-color: $gray-f8;
  background-image: url('../images/background-side.svg');
  padding-top: 15px;
  padding-bottom: 15px;
  border-right: 2px solid $blue-conacyt; //d4;
  @include all-transition;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  background-position: bottom;
  background-repeat: no-repeat;
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
  }
  .sidebar_header {
    padding: 7px 20px;
    font-weight: 600;
    text-transform: uppercase;
    background: $gris-20;
    font-size: 1 rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: 0px;
    letter-spacing: 0.3em;
    a {
      color: $azul-real-oscuro !important;
      text-decoration: none !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
  .when-items {
    align-items: center;
    display: flex;
  }
  .collapsed {
    .when-open {
      display: none !important;
    }
    .when-closed {
      display: inline-flex !important;
    }
  }
  .not-collapsed {
    .when-open {
      display: inline-flex !important;
    }

    .when-closed {
      display: none !important;
    }
  }
}
#page-content-wrapper {
  width: 100%;
}
/* Sidebar Styles */
@media (prefers-reduced-motion: reduce) {
  .sidebar {
    .sidebar__toggled {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
}
#app.toggled {
  #page-content-wrapper {
    position: absolute;
    margin-right: -50px;
  }
  .sidebar__toggled {
    position: relative;
    top: 0;
    right: 0;
    margin-right: 0;
  }
}
.dropdown-toggle {
  padding-right: 20px;
}
@media (min-width: 768px) {
  span.b-avatar.avatar_img {
    width: 3rem !important;
    height: 3rem !important;
  }
  #app {
    padding-left: 300px;
    &.toggled {
      padding-left: 50px;
    }
  }
  #sidebar-wrapper {
    width: 350px;

    .hide-toggle {
      @include fadeIn;
    }
  }
  #app.toggled #sidebar-wrapper {
    left: 0px;
    margin-left: 0;
    width: 50px !important;

    .hide-toggle {
      display: none;
    }

    .sidebar_grouper {
      display: none;
    }
  }

  #app.toggled #page-content-wrapper {
    position: relative;
    margin-right: 50px;
    @include all-transition;
  }
}

//menor a 768px sidebar
@media screen and (max-width: 768px) {
  #sidebar-wrapper {
    width: 50px !important;

    .sidebar__toggled,
    .sidebar_grouper {
      display: none;
    }
    .hide-toggle {
      @include fadeIn;
    }
    &:hover {
      width: 300px !important;
    }
  }
  #app.toggled {
    padding-left: 50px;
  }
}
.card {
  .card-header {
    background-color: $white;
    border: none;
  }
}
.step-indicator {
  margin: 60px 0px 30px 0;
  display: flex;
  align-items: center;
  padding: 0 10px;
  span {
    display: contents;

    &:last-child {
      div {
        &:last-child {
          display: none;
        }
      }
    }
  }
  .step {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    p {
      text-align: center;
      position: absolute;
      top: -35px;
      color: $primary !important;
      font-size: 14px;
      font-weight: bold;
    }
    &.completed .step-icon {
      background: $blue-conacyt;

      &:hover {
        background: $blue-conacyt;
      }
    }
    &.active {
      p {
        color: $blue-conacyt !important;
      }
      .step-icon {
        background: $blue-conacyt;
      }
    }
  }
  .step-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: $primary;
    font-size: 10px;
    text-align: center;
    position: relative;
    line-height: 50px;
    font-size: 20px;
    cursor: pointer;
    color: $white !important;
    @include all-transition;

    &:hover {
      background: $blue-conacyt;
      color: $white !important;
    }
  }
}
.indicator-line {
  width: 100%;
  height: 2px;
  background: $primary;
  flex: 1;
  &.active {
    background: $blue-conacyt;
  }
  &.completed {
    background: $blue-conacyt;
  }
}
@media screen and (max-width: 500px) {
  .step p {
    font-size: 11px;
    bottom: -20px;
  }
}

/*
   * FOOTER
   * --------------------------------------------------
   */
/* Footer at the bottom */

.popover {
  .popover-header {
    border-radius: calc(0.3rem - 1px);
  }
  .popover-body {
    padding: 0.3rem;
    ul {
      &.subitemsPopover {
        margin-bottom: 0 !important;
        .dropdown-item {
          padding: 0.25rem 0.5rem !important;
          color: #121212 !important;
          @include all-transition;
          &:hover {
            background-color: $secondary;
          }
          &:focus {
            background-color: $secondary;
            color: #212529 !important;
          }
        }
        .active {
          background-color: $secondary !important;
        }
      }
    }
  }
}
.ribbon {
  a {
    color: $white !important;
  }
}
.w-95 {
  width: 95% !important;
}

.form-control,
.custom-select,
.btn,
.border-file {
  @include border-radius(1.625rem !important, 0);
  border: 2px solid !important;
  border-color: $azul-real-oscuro !important;
  color: $gris-80 !important;
}

.form-control,
.custom-select {
  &:hover {
    transition: all 0.5s ease-out;
    border-color: $azul-onu !important;
  }
}

.border-file {
  border: 2px solid white !important;
  background-color: white;

  box-shadow: inset 0.2rem 0.35rem 0.6rem rgba(49, 112, 143, 0.35) !important;
  -webkit-box-shadow: inset 0.2rem 0.35rem 0.6rem rgba(49, 112, 143, 0.35) !important;
  -moz-box-shadow: inset 0.2rem 0.35rem 0.6rem rgba(49, 112, 143, 0.35) !important;
  transition: all 0.5s ease-out;
  &:hover {
    box-shadow: inset 0.2rem 0.35rem 0.4rem rgba(49, 112, 143, 0.4) !important;
    -webkit-box-shadow: inset 0.2rem 0.35rem 0.4rem rgba(49, 112, 143, 0.4) !important;
    -moz-box-shadow: inset 0.2rem 0.35rem 0.4rem rgba(49, 112, 143, 0.4) !important;
  }
  p {
    color: $gris-50;
  }

  .btn-outline-primary {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    transition: all 0.5s ease-out;

    &:hover {
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
    }
  }
}

.border-file.border-file-active {
  border: 2px solid white !important;
  box-shadow: -0.2rem 0.35rem 0.6rem rgba(49, 112, 143, 0.3) !important;
  -webkit-box-shadow: -0.2rem 0.35rem 0.6rem rgba(49, 112, 143, 0.3) !important;
  -moz-box-shadow: -0.2rem 0.35rem 0.6rem rgba(49, 112, 143, 0.3) !important;
}
.btn-lg,
.btn {
  font-size: 1rem !important;
  line-height: 1.375rem !important;
  font-weight: 600 !important;
  letter-spacing: 0.05em !important;
  transition: all 0.7s ease-out !important;
}
.btn {
  margin: 0px 0.5rem 0.5rem 0.5rem !important;
  &.disabled,
  &:disabled {
    background-color: $gris-50 !important;
    border-color: $gris-50 !important;
    color: white !important;
    opacity: 1 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }
  &.disabled:hover {
    background-color: $gris-50 !important;
    border-color: $gris-50 !important;
    color: white !important;
    opacity: 1 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }
}

button {
  .mr-2 {
    margin-left: 0.5rem;
    margin-right: 0px !important;
    .svg-inline--fa {
      height: 1rem !important;
      width: auto !important;
    }
  }
}

.btn-primary {
  background-color: $azul-real-oscuro !important;
  border-color: $azul-real-oscuro !important;
  color: white !important;
  box-shadow: -0.2rem 0.3rem 0.5rem rgba(49, 112, 143, 0.4);
  -webkit-box-shadow: -0.2rem 0.3rem 0.5rem rgba(49, 112, 143, 0.4);
  -moz-box-shadow: -0.2rem 0.3rem 0.5rem rgba(49, 112, 143, 0.4);
  &:focus,
  &:hover {
    background-color: $azul-onu !important;
    border-color: $azul-onu !important;
    box-shadow: -0.2rem 0.3rem 0.17rem rgba(49, 112, 143, 0.3) !important;
    -webkit-box-shadow: -0.2rem 0.3rem 0.17rem rgba(49, 112, 143, 0.3) !important;
    -moz-box-shadow: -0.2rem 0.3rem 0.17rem rgba(49, 112, 143, 0.3) !important;
  }
  &:active {
    background-color: $azul-real-oscuro !important;
    border-color: $verde-claro !important;
    color: $verde-claro !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }
}

.btn-outline-primary {
  background-color: $white !important;
  color: $azul-real-oscuro !important;
  border-color: $azul-real-oscuro !important;
  box-shadow: -0.2rem 0.3rem 0.5rem rgba(49, 112, 143, 0.4) !important;
  -webkit-box-shadow: -0.2rem 0.3rem 0.5rem rgba(49, 112, 143, 0.4) !important;
  -moz-box-shadow: -0.2rem 0.3rem 0.5rem rgba(49, 112, 143, 0.4) !important;
  transition: all 1s ease-out;
  &:hover,
  &:focus {
    color: $azul-onu !important;
    border-color: $azul-onu !important;
    box-shadow: -0.2rem 0.3rem 0.17rem rgba(49, 112, 143, 0.3) !important;
    -webkit-box-shadow: -0.2rem 0.3rem 0.17rem rgba(49, 112, 143, 0.3) !important;
    -moz-box-shadow: -0.2rem 0.3rem 0.17rem rgba(49, 112, 143, 0.3) !important;
  }
  &:active {
    border-color: $verde-claro !important;
    background-color: white;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    color: $gris-100 !important;
  }
}

.modal-content {
  border-radius: 1.3rem !important;
  border: none !important;

  .modal-header {
    border-radius: 1.3rem 1.3rem 0 0 !important;
    background: $blue-conacyt !important;
    h5 {
      color: $white !important;
    }
    .close {
      color: $white !important;
    }
  }
}
.b-toaster.b-toaster-top-right .b-toaster-slot,
.b-toaster.b-toaster-top-left .b-toaster-slot,
.b-toaster.b-toaster-top-center .b-toaster-slot,
.b-toaster.b-toaster-top-full .b-toaster-slot {
  margin-top: 55px !important;
}
.b-toast {
  border: none !important;
  box-shadow: 0 0.25rem 0.75rem rgba(49, 112, 143, 0.4) !important;

  .toast-header {
    padding: 0rem !important;
    font-size: 1rem !important;
    .close {
      float: right;
      margin-right: 0.5rem !important;
      text-shadow: none !important;
      opacity: 1 !important;
    }
    .mb-1 {
      margin-bottom: 0rem !important;
    }
  }
  .toast {
    background-color: rgba($white, 100%);
    border-color: $white;
    border-left-width: 8px;
    border-left-style: solid;
    padding: 10px 0 10px 10px;
    border-bottom-width: 2px;
    border-right-width: 2px;
    border-top-width: 2px;
  }
  .toast,
  .toast-header {
    color: $black-141414 !important;

    .close {
      color: $text-color !important;
      @include all-transition;

      &:hover {
        color: $text-color !important;
      }
    }
  }
  .toast-body {
    padding: 0.75rem 0 !important;
    font-size: 1.25rem !important;
    font-weight: 400 !important;
  }
}
.b-toast-success {
  .toast {
    background-color: $succes-bg !important;
    border-color: $succes !important;
    color: $succes-text !important;
    .toast-header {
      background-color: $succes-bg !important;
      border-bottom-color: transparent !important;
      color: $succes-text !important;
      .close {
        color: $succes !important;
        @include all-transition;

        &:hover {
          color: $succes !important;
        }
      }
    }
  }
}
.b-toast-info {
  .toast {
    background-color: $white !important;
    border-color: $info !important;
    color: $info !important;
    .toast-header {
      background-color: $white !important;
      border-bottom-color: transparent !important;
      color: $info !important;
      .close {
        color: $info !important;
        @include all-transition;

        &:hover {
          color: $info !important;
        }
      }
    }
  }
}
.b-toast-warning {
  .toast {
    background-color: $warning-bg !important;
    border-color: $warning !important;
    color: $warning-text !important;
    .toast-header {
      background-color: $warning-bg !important;
      border-bottom-color: transparent !important;
      color: $warning-text !important;
      .close {
        color: $warning !important;
        @include all-transition;

        &:hover {
          color: $warning !important;
        }
      }
    }
  }
}

.b-toast-danger {
  .toast {
    background-color: $danger-bg !important;
    border-color: $danger !important;
    color: $danger-text !important;
    .toast-header {
      background-color: $danger-bg !important;
      border-bottom-color: transparent !important;
      color: $danger-text !important;
      .close {
        color: $danger !important;
        @include all-transition;

        &:hover {
          color: $danger !important;
        }
      }
    }
  }
}

.b-toast-primary {
  .toast {
    background-color: $primary-bg !important;
    border-color: $primary-border !important;
    color: $primary-text !important;
    .toast-header {
      background-color: $primary-bg !important;
      border-bottom-color: transparent !important;
      color: $primary-text !important;
      .close {
        color: $primary-border !important;
        @include all-transition;

        &:hover {
          color: $primary-border !important;
        }
      }
    }
  }
}
.navbar-dark {
  background-color: $azul-real-oscuro !important;

  .navbar-nav {
    .nav-link {
      color: $white !important;
      font-weight: 500;

      &:hover {
        color: $verde-claro !important;
        transition: all 0.7s ease-out;
      }
    }
  }
}

.progress {
  border-radius: 1rem !important;
  background-color: $gris-20 !important;
  .bg-primary {
    background-color: $azul-real-oscuro !important;
    border-radius: 1rem !important;
  }
}
.card-text {
  word-break: break-word;
}
.btn-link {
  border: none !important;
  padding: 0 !important;
  font-weight: 700 !important;
}

.input-group {
  .input-group-text {
    background: #fff !important;
    border: solid $azul-real-oscuro !important;
    border-width: 2px 0 2px 2px !important;
    border-top-left-radius: 1.15rem !important;
    border-bottom-left-radius: 1.15rem !important;
    padding: 0.3rem 0.75rem !important;
    transition: all 0.28s ease-out !important;
  }

  .icon-calendario {
    color: $azul-real-oscuro;
    .input-group:hover & {
      color: $azul-onu;
    }
  }

  .vdpComponent input {
    border: 2px solid $azul-real-oscuro !important;
    transition: all 0.55s ease-out;
    .input-group:hover & {
      border: 2px solid $azul-onu !important;
    }
  }
  &:hover {
    .input-group-text {
      border: solid $azul-onu !important;
      border-width: 2px 0 2px 2px !important;
    }
    .icon-calendario {
      color: $azul-onu;
    }
    .vdpComponent input {
      border: 2px solid $azul-onu !important;
    }
  }
}

.is-disabled {
  .input-group-text {
    background: #e9ecef !important;
    border-color: $gris-50 !important;
    background-color: transparent !important;
    color: $gris-50 !important;
  }
  .icon-calendario {
    color: $gris-50 !important;
  }
  &:hover {
    .input-group-text {
      background: #e9ecef !important;
      border-color: $gris-50 !important;
      background-color: transparent !important;
      color: $gris-50 !important;
    }
    .icon-calendario {
      color: $gris-50 !important;
    }
  }
}
.is-invalid {
  .icon-calendario {
    color: $danger;
  }
  .input-group-text {
    border: 2px solid $danger !important;
    border-right: none !important;
  }
}
.is-valid {
  .icon-calendario {
    color: $succes;
  }
  .input-group-text {
    border: 2px solid $succes !important;
    border-right: 0px !important;
  }
}
.vdpInnerWrap {
  border-radius: 1rem;
  border: 2px solid $azul-al;
  background-color: white;
  box-shadow: -0.2rem 0.3rem 0.17rem rgba(49, 112, 143, 0.3);
  -webkit-box-shadow: -0.2rem 0.3rem 0.2rem rgba(49, 112, 143, 0.3);
  -moz-box-shadow: -0.2rem 0.3rem 0.2rem rgba(49, 112, 143, 0.3);
  .vdpHeader {
    background-color: $gris-10;
    border-bottom: 1px solid $gris-50;
    .vdpArrowNext::after {
      border-left-color: $azul-onu;
    }
    .vdpArrowPrev::after {
      border-right-color: $azul-onu;
    }
    .vdpPeriodControl > button {
      color: $azul-onu;
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .vdpHeadCellContent {
    color: $gris-50;
    font-size: 0.625rem;
    text-transform: uppercase;
  }
}
.vdpCell {
  color: $azul-real-oscuro;
  .outOfRange {
    color: $gris-50;
  }
}
.vdpCell.selected .vdpCellContent {
  background-color: $azul-onu;
  font-weight: 600;
}
.vdpCell.today .vdpCellContent {
  background-color: $azul-al;
}
.vdpClearInput {
  &::before {
    color: white !important;
    background-color: $azul-real-oscuro;
    border: 1px solid $azul-real-oscuro;
    font-size: 0.7rem;
    font-weight: 700;
    transition: all 0.3s ease-out;
  }
  &:hover::before {
    border-color: $azul-onu;
    background-color: $azul-onu;
  }
}

.card-header {
  &:first-child {
    -webkit-border-top-left-radius: 20px !important;
    -webkit-border-top-right-radius: 20px !important;
    -moz-border-radius-topleft: 20px !important;
    -moz-border-radius-topright: 20px !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }
  font-weight: bolder;
}
//cambio de peso en invalid-feedback helper text

.progress-bar.bg-info {
  background-color: $azul-onu !important;
  padding: 5px;
}
.mb-4.shadow-sm.border.p-4.rounded-lg {
  background-color: $blanco;
}
.badge-primary {
  background-color: $primary !important;
}
.badge-success {
  background-color: $verde !important;
}
.badge-danger {
  background-color: $bermellon !important;
}
