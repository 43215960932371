@import 'rizoma-theme';
// @import '~@conacyt/rizoma-theme/dist/scss/rizoma-theme';

// movil first

html {
  font-size: $font-size-root;
}
body {
  background-color: $body-color !important;
}

#sidebar-wrapper {
  display: none;
}
#app {
  width: 100%;
  padding-left: 0px;
}

/*navbar */
nav.navbar {
  justify-content: space-around !important;
  align-content: center !important;
  align-items: center !important;
  display: flex !important;
  padding: 0.5rem !important;
  .navbar-brand {
    padding: 0px;
    margin: 0px;
    flex-shrink: 1;
  }
  .logo-rizoma {
    margin-left: auto;
    margin-right: auto;
    width: 90px;
    flex-shrink: 1;
  }
  button.navbar-toggler {
    padding: 0.5rem;
    margin-left: auto;
    flex-shrink: 1;
    .navbar-toggler-icon {
      width: 1.4em;
      height: 1.4em;
    }
  }
  .navbar-toggler.not-collapsed {
    margin-left: 0px;
  }
  #nav-collapse {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .navbar-nav {
      display: flex;
      list-style: none;
      padding: 0;
    }
  }
}

/*header */
.header-conacyt.sticky-top {
  top: 62px;
  z-index: 900;
  height: 50px;
  transition: all 0.25s ease-out;
}

.header-conacyt.sticky-top.engrozar {
  height: 75px;
}
.user_cvu span.muestra {
  display: flex;
}

.user_cvu span.esconde {
  display: none;
  opacity: 0.1;
}

.header-conacyt {
  height: 80px;
  background-color: $blue-conacyt;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  .container {
    width: 100%;
    padding: 0;
  }
  #header-2a {
    margin: 0 !important;
    padding: 0rem;
    flex-direction: column;
  }
  span.b-avatar.avatar_img {
    display: none;
    width: 3rem;
    height: 3rem;
    animation: fade-in 500ms forwards;
  }
  .avatar span {
    font-size: 1rem;
    line-height: 1.1rem;
    font-weight: 600;
    color: $white;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
  .user_cvu {
    margin: 0;
    align-items: end;
    text-align: right;
  }
  .user_cvu span {
    color: $white;
    font-size: 0.75rem;
    line-height: 1.2rem;
    letter-spacing: 0.1em;
  }
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Main page styles
========================================================================== */

.hipster.img-fluid {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url('../images/jhipster_family_member_0.svg') no-repeat center top;
  background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url('../images/jhipster_family_member_0.svg') no-repeat center top;
    background-size: contain;
  }
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.valid[required],
.valid.required {
  border-left: 5px solid $success;
}

.invalid:not(form) {
  border-left: 5px solid $danger;
}

/* other generic styles */

.jh-card {
  padding: 1.5%;
  margin-top: 20px;
  border: none;
}

.error {
  color: white;
  background-color: $danger;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.hand,
[jhisortby] {
  cursor: pointer;
}
.badge-danger {
  background-color: $bermellon !important;
}
.badge-primary {
  background-color: $azul-onu !important;
}
.badge-secondary {
  background-color: $gris-50;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;
    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    .popover pre {
      font-size: 10px;
    }
  }
  .toast {
    position: fixed;
    width: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
    &.top {
      top: 55px;
    }
    &.bottom {
      bottom: 55px;
    }
  }
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
@mixin no-padding($side) {
  @if $side == 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}
@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');
/* bootstrap 3 input-group 100% width
  http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important;
}
/* ==========================================================================
  entity detail page css
  ========================================================================== */
.row.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}
/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.card-title a {
  cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-secondary,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-secondary {
  border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/****** TABLE - Schedule / Fixed positions ******/
table.table-schedule {
  border-collapse: separate;
  border-spacing: 0;
}
.table-schedule td:last-child,
.table-schedule th:last-child {
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: white;
  text-align: center;
}
.table-schedule thead tr th {
  top: 0;
}
.table-schedule th:first-child,
.table-schedule th:last-child {
  z-index: 2;
  // background-color: $secondary;
}
/****** Hover ******/
.table-schedule {
  background-color: #fff;
}
.table-schedule tbody tr:hover,
.table-schedule tbody tr:hover td:first-child,
.table-schedule tbody tr:hover td:last-child {
  background-color: $gray-100 !important;
}
.text-primary {
  color: $primary !important;
}

a {
  &.fw-bold {
    font-weight: bold !important;
  }
}
.btn-link {
  color: $primary !important;
  text-underline-offset: 0.3em;
}
.table-schedule {
  .table-info {
    td {
      &:last-child {
        // background-color: #bee5eb !important;
      }
    }
  }
  .table-secondary {
    td {
      &:last-child {
        // background-color: #d6d8db !important;
      }
    }
  }
  .table-dark {
    td {
      &:last-child {
        background-color: #c6c8ca !important;
      }
    }
  }
}
.custom-dropdown {
  margin-left: 15px;
  .btn {
    padding: 0px !important;
  }
  .dropdown-menu {
    &.show {
      position: fixed !important;
    }
  }
  .badge {
    float: right;
  }
}
.b-table-details {
  .card-body {
    padding: 0.5rem;
  }
  .card-title {
    font-size: 0.9rem;
  }
}
.table-primary {
  td {
    background-color: #b8daff !important;
  }
}
.table-danger {
  td {
    background-color: #f5c6cb !important;
  }
}
.table-warning {
  td {
    background-color: #ffeeba !important;
  }
}
.table-success {
  td {
    background-color: #c3e6cb !important;
  }
}
.table-info {
  td {
    background-color: #bee5eb !important;
  }
}
.popover {
  .popover-body {
    ul {
      &.subitemsPopover {
        .dropdown-item {
          white-space: normal !important;
        }
      }
    }
  }
}
.sidebar {
  .item-text {
    overflow: hidden;
  }
  .subitems {
    li {
      .dropdown-item {
        white-space: normal !important;
      }
    }
  }
  .collapse {
    .subitems {
      background-color: #eee !important;
    }
    .subitems-2 {
      background-color: #e7e7e7 !important;
    }
  }
}

.form-group,
fieldset {
  label {
    font-weight: 600 !important;
    size: 1rem !important;
    line-height: 1.375rem !important;
    letter-spacing: 0.05em !important;
    color: $gris-100;
  }
}
.card {
  border: none !important;
  @include b-shadow;
  border-radius: 1.625rem !important;
}

.label-hidden {
  height: 30px;
  display: block;
}
.m-h3 {
  margin-top: 3.125rem !important; /* Equivalente a 50px*/
  margin-bottom: 1.875rem !important; /* Equivalente a 30px*/
}
.label {
  font-weight: 600 !important;
}
.inline {
  display: inline !important;
}
.multiselect-tags {
  label {
    margin: 0px !important;
  }
  .msl-multi-select {
    display: flex;
    flex-direction: column-reverse;
    .msl-search-list-input,
    .msl-multi-select__actions {
      display: none !important;
      margin: 10px;
    }
    .msl-searchable-list__no-item {
      display: none;
    }
    .msl-searchable-list__items {
      margin-top: 10px;
      display: block;
      .msl-searchable-list__item {
        cursor: pointer;
        display: inline-block !important;
        margin: 0 10px 5px 0 !important;
        color: $gris-80 !important;
        text-align: center;
        vertical-align: middle;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        background-color: #fff !important;
        border: 2px solid white !important;
        border-radius: 1.6rem !important;
        transition: all 0.3s ease-out;
        &:hover {
          border: 2px solid $azul-onu !important;
          color: $azul-onu !important;
        }
      }
      .msl-searchable-list__item--added {
        background-color: $azul-al !important;
        color: $azul-real-oscuro !important;
        border-color: $azul-al !important;
        border-radius: 1.6rem !important;
      }
    }
    .msl-multi-select__selected {
      .msl-searchable-list__item {
        background-color: $azul-al !important;
        color: $azul-real-oscuro !important;
        border: 2px solid $azul-al !important;
        border-radius: 1.6rem !important;
        font-weight: 500 !important;
      }
    }
  }
}

.custom-switch {
  .custom-control-label {
    &::before {
      background-color: $azul-real-oscuro !important;
      border: 2px solid $azul-real-oscuro !important;
      padding: 0.2em !important;
    }
    &:hover::before {
      border: 2px solid $azul-onu !important;
    }
    &::after {
      background-color: white !important;
    }
  }
}

.custom-control-label::before {
  background-color: white !important;
  border: 2px solid $azul-real-oscuro !important;
}
.custom-control-label:hover::before {
  border: 2px solid $azul-onu !important;
  transition: all 0.3s ease-out;
}

input.custom-control-input[type='checkbox'],
.custom-control-input {
  border: 2px solid $azul-real-oscuro !important;
}
input.custom-control-input[type='checkbox']:hover {
  border: 2px solid $azul-onu !important;
}

.custom-control-input[disabled] ~ label,
.custom-control-input:disabled ~ label {
  color: $gris-50 !important;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  border-color: $gris-50 !important;
  background-color: $gris-10 !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $exito !important;
  background-color: $exito !important;
}

.custom-select:focus,
.form-control:focus {
  border: 2px solid $azul-real-oscuro !important;
  box-shadow: 0 0 0 0.25em $azul-al !important ;
  color: $gris-100 !important;
  font-weight: 500 !important;
  &::placeholder {
    font-weight: 400 !important;
  }
}

.custom-select.is-invalid,
.form-control.is-invalid {
  border: 2px solid $danger !important;
  background-color: white !important;
  font-weight: 500 !important;
  color: $gris-100 !important;
}

.custom-select.is-valid,
.form-control.is-valid {
  border: 2px solid $succes !important;
  color: $gris-100 !important;
  background-color: white !important;
  font-weight: 400 !important;
}

.form-control:disabled,
.form-control[readonly],
.custom-select:disabled {
  background-color: $gris-10 !important;
  border-color: $gris-50 !important;
  color: $gris-50 !important;
  opacity: 1 !important;
  &:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-color: $gris-50 !important;
    color: $gris-50 !important;
    font-weight: 400 !important;
  }
  &::placeholder {
    font-weight: 400 !important;
  }
}

.invalid-feedback {
  font-weight: 600 !important;
  font-size: 0.8rem;
  letter-spacing: 0.02em;
  color: $danger !important;
  text-transform: lowercase;
}
.invalid-feedback::first-letter {
  text-transform: uppercase !important;
}
.border-primary {
  border: 1px solid $blue-conacyt !important;
}
.border-secondary {
  border: 1px solid $secondary !important;
}
.border-warning {
  border: 1px solid $warning !important;
  .card-subtitle {
    margin-top: 10px;
  }
}
/* jhipster-needle-scss-add-main JHipster will add new css style */
/* menu-tabbar */
#subitems {
  li {
    .dropdown-item {
      padding: 0.25rem 2rem;
      text-align: left;
      border-bottom: 1px solid rgba(0, 5, 74, 0.075);
      span {
        white-space: normal;
        font-size: 16px;
      }
      &:hover {
        background-color: #001a44;
        color: #fff !important;
        border-bottom: 2px solid #38e109 !important;
      }
      &:focus {
        background-color: #001a44;
        color: #fff !important;
        border-bottom: 2px solid #38e109 !important;
      }
      &.active,
      &:active {
        background-color: #001a44 !important;
        border-bottom: 2px solid #38e109 !important;
        color: #fff !important;
      }
    }
    &.active {
      background-color: $secondary !important;
      .dropdown-item {
        border-left: 4px solid $blue-conacyt !important;
      }
    }
  }
}
.btn {
  span {
    &:last-child {
      font-weight: 800 !important;
    }
  }
}
.btn-custom-acciones {
  .btn {
    border: 0px solid transparent !important;
  }
}
.page-item.active .page-link {
  background-color: $blue-conacyt !important;
  border-color: $blue-conacyt !important;
  color: white !important;
}
.page-link {
  color: $blue-conacyt !important;
}
.table {
  td {
    border-top: transparent !important;
  }
  .thead-dark {
    th {
      background-color: #002663 !important;
      border-color: transparent !important;
      [class^='icon-'],
      [class*=' icon-'] {
        font-weight: 600;
      }
      &:first-child {
        border-radius: 20px 0 0 0 !important;
      }
      &:last-child {
        border-radius: 0 20px 0 0 !important;
      }
    }
  }
}
.card-footer {
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}
.border-danger {
  border: 1px solid $danger !important;
}
.border-success {
  border: 1px solid $success !important;
}
.avance {
  .text {
    font-size: 1rem;
    font-weight: 500;
  }
  span {
    font-size: 1.8rem;
  }
}

.indentation20 {
  text-indent: 20px;
}
.indentation40 {
  text-indent: 40px;
}
/*
    * ALERTAS
    * --------------------------------------------------
    */
.alert {
  border-left-width: 4px !important;
  border-left-style: solid !important;
  border-radius: 0.25rem !important;
  font-size: 20px !important;
  margin: 1.25rem 0 1.25rem !important;
}
.alert.alert-primary,
.alert.alert-info {
  border-color: $primary-border !important;
  background: $primary-bg !important;
  color: $primary-text !important;
}
.alert.alert-primary::before,
.alert.alert-info::before {
  background: $primary-border !important;
}
.alert.alert-success {
  border-color: $succes !important;
  background: $succes-bg !important;
  color: $succes-text !important;
}
.alert.alert-success::before {
  background: $succes !important;
}
.alert.alert-warning {
  border-color: $warning !important;
  background: $warning-bg !important;
  color: $warning-text !important;
}
.alert.alert-warning::before {
  background: $warning !important;
}
.alert.alert-danger {
  border-color: $danger !important;
  background: $danger-bg !important;
  color: $danger-text !important;
}
.alert.alert-danger::before {
  background: $danger !important;
}
.alert.alert-warning::before {
  content: '';
  background: url('../images/icons/avisos/precaucion_c.svg') no-repeat center !important;
}
.alert.alert-danger::before {
  content: '';
  background: url('../images/icons/avisos/error_c.svg') no-repeat center !important;
}
.alert.alert-success::before {
  content: '';
  background: url('../images/icons/avisos/afirmacion_c.svg') no-repeat center !important;
}
.alert.alert-primary::before,
.alert.alert-info::before {
  content: '';
  background: url('../images/icons/avisos/especificar_c.svg') no-repeat center !important;
}
.alert::before {
  position: absolute;
  top: 14px;
  left: -12px;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  font-size: 12px;
}
.navbar-brand {
  .conahcyt {
    width: 127px;
  }
}
.soluciones-card {
  .btn-link {
    text-align: left !important;
  }
}
.lower-alpha {
  list-style: lower-alpha;
}
.aviso-custom-modal {
  justify-content: space-between !important;
}
.custom-sidebar {
  width: 100% !important;
  top: 50% !important;
}
.fixed {
  position: fixed;
  bottom: 4rem;
  z-index: 999;
  width: 60%;
  box-shadow: -0.2rem 0.3rem 0.2rem rgba(49, 112, 143, 0.3);
  -webkit-box-shadow: -0.2rem 0.3rem 0.2rem rgba(49, 112, 143, 0.3);
  -moz-box-shadow: -0.2rem 0.3rem 0.2rem rgba(49, 112, 143, 0.3);
  border-radius: 20px;
  border: 2px solid $gris-10;
  /* Estilos adicionales para el div fijo */
}

.paginado-custom {
  .progress-bar {
    display: -webkit-inline-box !important;
  }
}
.padding-badge {
  padding: 0.8rem !important;
}
.custom-badge-primary {
  .badge-primary {
    margin-top: 0 !important;
  }
}

.container-f {
  .btn {
    margin: 0px !important;
  }

  .drop-wrapper {
    box-shadow: 0.17rem -0.3rem 0.3rem rgba(49, 112, 143, 0.2);

    .btn.rounded-0 {
      background-color: $azul-real-oscuro;
      border: 2px solid $gris-10 !important;
      border-left: 0px !important;
      display: inline-flex;
      justify-content: center;
      align-content: center;
      padding: 0.5rem;

      .b-icon.bi,
      .icon-empleo::before,
      .icon-educacion::before {
        color: $gris-10 !important;
        margin: auto;
      }
      .item-text {
        color: $gris-10;
        font-weight: 600;
        font-size: 0.625rem;
        line-height: 10px;
        margin: auto;
      }
    }
    .btn.rounded-0:hover {
      background-color: $azul-onu !important;
    }

    .drop-menu {
      .dm-2 {
        bottom: 2.7rem;
        background-color: white;
        border: 2px solid $azul-al;
        box-shadow: 0.17rem -0.3rem 0.2rem rgba(49, 112, 143, 0.3);

        .titulo-drop {
          color: $azul-real-oscuro;
          font-size: 1rem;
          font-weight: 400;
        }

        #subitems li .dropdown-item span {
          font-size: 0.8rem;
        }
      }
    }
  }
}

#footer-2 .col-md-6 {
  width: 100%;
}

.card-btn {
  .row button.btn.btn-outline-primary,
  .btn.btn-outline-primary {
    border: 0px solid !important ;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }
  .custom-input-boolean {
    .custom-control {
      display: inline;
      margin-left: 5px;
    }
  }
  .btn {
    border: none;
    padding: 0.1rem 0.1rem;
    margin: 1px !important;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(21 83 183 / 0%) !important;
    }
    span {
      border: solid 2px $azul-real-oscuro;
      padding: 6px;
      border-radius: 50%;
      font-weight: 600 !important;
      transition: all 0.7s ease-out;
      &:hover {
        border: 2px solid $azul-onu;
        background-color: white !important;
      }
      &::before {
        color: $azul-real-oscuro;
        transition: all 0.7s ease-out;
      }
      &:hover::before {
        color: $azul-onu;
      }

      &:active {
        border-color: $exito !important;
        background-color: white;
      }
      &:active::before {
        color: $exito;
      }
    }
    label {
      font-weight: 600 !important;
      font-size: 10px;
      display: block;
      color: $azul-real-oscuro;
      letter-spacing: 0px !important;
      margin: 0px !important;
    }
  }
  .disabled {
    background: white !important;
    border: none;
    &:hover {
      background-color: white !important;
    }

    label {
      color: $gris-50;
    }

    span {
      background: $disabled-bg;
      border-color: $gris-50;
      color: $gris-50;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;

      &::before {
        color: $gris-50 !important;
      }
      &:hover {
        border: 2px solid $gris-50;
        box-shadow: -0.1rem 0.2rem 0.1rem rgba(49, 112, 143, 0.3);
        -webkit-box-shadow: -0.1rem 0.2rem 0.1rem rgba(49, 112, 143, 0.3);
        -moz-box-shadow: -0.1rem 0.2rem 0.1rem rgba(49, 112, 143, 0.3);
      }
      &:active {
        border: 2px solid $gris-50 !important;
        background-color: $disabled-bg;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
      }
    }
  }
}

@media screen and (min-width: 338px) {
  .header-conacyt #header-2a {
    margin: 0 !important;
    flex-direction: column;
  }
}
@media screen and (min-width: 480px) {
  .header-conacyt {
    align-items: flex-end;
  }
  .alerts .toast {
    width: 50%;
  }
}
@media screen and (min-width: 520px) {
  .b-avatar.avatar_img.badge-secondary.rounded-circle.muestra {
    display: flex;
  }

  .b-avatar.avatar_img.badge-secondary.rounded-circle.esconde {
    display: none;
    opacity: 0.1;
  }

  .header-conacyt {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
    .container {
      width: 100%;
      padding: 0;
    }
    #header-2a {
      margin: 0 !important;
      padding: 0.1rem;
      flex-direction: row;
      align-items: center;
    }
    span.b-avatar.avatar_img {
      width: 3.5rem;
      height: 3.5rem;
      margin: 0 1rem 0 0;
      display: block;
    }
    .avatar span {
      font-size: 0.9rem;
      line-height: 1.3rem;
      letter-spacing: 0.2em;
    }
    .user_cvu {
      margin: 0;
      align-items: end;
      text-align: right;
    }
    .user_cvu span {
      font-size: 0.73rem;
      line-height: 1rem;
      letter-spacing: 0.2em;
      margin: 0;
      font-weight: 500;
    }
  }
  #subitems li .dropdown-item span {
    font-size: 14px;
  }
  #subitems li .dropdown-item {
    padding: 0.25rem 1rem;
  }
  .btn {
    display: flex;
  }
  nav.navbar a.navbar-brand {
    margin-right: auto;
  }
  .nav-collapse-2 {
    display: flex;
    flex-direction: column;
  }
  ul.navbar-nav {
    text-align: right;
  }
}
@media screen and (min-width: 768px) {
  .header-conacyt {
    align-items: normal;
  }

  .row.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }
    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
  .datos-evalucacion {
    .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .header-conacyt .container {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  .avance {
    span {
      font-size: 1rem;
    }
  }
  .nav-collapse-2 {
    flex-direction: column;
    text-align: right;
  }
}
@media screen and (min-width: 1000px) {
  .header-conacyt,
  .header-conacyt #header-2a {
    justify-content: center;
    align-items: normal;
  }
  .header-conacyt .avatar span {
    font-size: 1rem;
    line-height: 1.3rem;
    letter-spacing: 0.2em;
  }
  .header-conacyt .user_cvu span {
    font-size: 0.8rem;
    line-height: 1.125rem;
    letter-spacing: 0.2em;
  }

  span.b-avatar.avatar_img {
    width: 3rem;
    height: 3rem;
  }
  #sidebar-wrapper {
    display: block;
  }
  #app {
    width: 100%;
    padding-left: 350px;
  }
  ul.navbar-nav {
    text-align: left;
  }
}
@media screen and (min-width: 1200px) {
  .header-conacyt {
    justify-content: center;
    align-items: normal;
    top: 56px;
  }
  .header-conacyt.sticky-top {
    top: 56px;
  }
  .navbar li.nav-item {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
  .navbar li.nav-item:first-child {
    padding-left: 1rem;
  }
  nav.navbar a.navbar-brand {
    padding-left: 0.5rem;
  }
  .navbar-right {
    margin-left: 60px;
  }
  .navbar-nav {
    text-align: left;
  }
  .nav-collapse-2 {
    justify-content: space-between;
    flex-direction: row;
  }
  .custom-card {
    .custom-listado {
      top: -4px;
      left: -4px;
    }
    .card-body {
      padding: 0.8rem 1.25rem;
    }
    ul {
      margin: 0;
    }
    .border-success,
    .border-danger,
    .border-primary {
      border-radius: 25px;
      border-width: 2px !important;
      .registro-completo,
      .registro-incompleto {
        display: table-column;
        opacity: 0;
      }
    }
    .border-success {
      border-color: $verde !important;
      &:hover {
        .registro-completo {
          display: block;
          position: absolute;
          top: -19px;
          left: 35px;
          background: $verde;
          padding: 1px 13px;
          font-size: 12px;
          color: #fff;
          border-radius: 4px 4px 0px 0px;
          font-weight: 600;
          transition: background-color 1s ease 0s;
          opacity: 1;
        }
      }
    }
    .border-danger {
      border-color: $bermellon !important;
      &:hover {
        .registro-incompleto {
          display: block;
          position: absolute;
          top: -19px;
          left: 35px;
          background: $bermellon;
          padding: 1px 13px;
          font-size: 12px;
          color: #fff;
          border-radius: 4px 4px 0px 0px;
          font-weight: 600;
          transition: background-color 1s ease 0s;
          opacity: 1;
        }
      }
    }
    .border-primary {
      border-color: $primary !important;
    }
  }
}
.switch-lineal {
  .form-group {
    .custom-control {
      display: inline !important;
      float: right !important;
    }
    label {
      width: 80%;
    }
  }
}
.fixed-footer-menu {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  z-index: 100;
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 350px);
  margin-right: auto;
  margin-left: auto;
  background-color: $blanco;
  opacity: 0.95;
  padding: 10px;
  box-shadow: 0.17rem -0.2rem 0.3rem rgba(49, 112, 143, 0.2);

  .btn {
    margin: 0px 0.5rem 0rem 0.5rem !important;
    box-shadow: none !important;

    span.badge.circulo {
      display: inline-block;
      background-color: $azul-real-oscuro;
      border-radius: 50%;
      margin-right: 0.5em;
      color: $blanco;
    }
    .blanco {
      color: $azul-real-oscuro !important;
      background-color: $blanco !important;
    }
  }
}

.custom-file-label {
  border-radius: 25px !important;
  border-width: 2px !important;
}
.docs-btn {
  label {
    font-weight: 600 !important;
    size: 1rem !important;
    line-height: 1.375rem !important;
    letter-spacing: 0.05em !important;
    color: #161616;
  }
  .btn-outline-primary,
  .btn-outline-danger {
    border: 0px solid !important ;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }
  .btn-outline-primary {
    border: none;
    padding: 0.1rem 0.1rem;
    margin: 1px !important;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(21 83 183 / 0%) !important;
    }
    span {
      border: solid 2px $azul-real-oscuro;
      padding: 6px;
      border-radius: 50%;
      font-weight: 600 !important;
      transition: all 0.7s ease-out;
      &:hover {
        border: 2px solid $azul-onu;
        background-color: white !important;
      }
      &::before {
        color: $azul-real-oscuro;
        transition: all 0.7s ease-out;
      }
      &:hover::before {
        color: $azul-onu;
      }

      &:active {
        border-color: $exito !important;
        background-color: white;
      }
      &:active::before {
        color: $exito;
      }
    }
  }
  .btn-outline-danger {
    border: none;
    padding: 0.1rem 0.1rem;
    margin: 1px !important;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(21 83 183 / 0%) !important;
    }
    span {
      border: solid 2px $bermellon;
      padding: 6px;
      border-radius: 50%;
      font-weight: 600 !important;
      transition: all 0.7s ease-out;
      background-color: white;
      &:hover {
        border: 2px solid $bermellon;
        background-color: white !important;
      }
      &::before {
        color: $bermellon;
        transition: all 0.7s ease-out;
      }
      &:hover::before {
        color: $bermellon;
      }

      &:active {
        border-color: $exito !important;
        background-color: white;
      }
      &:active::before {
        color: $exito;
      }
    }
  }
  .disabled {
    background: white !important;
    border: none;
    &:hover {
      background-color: white !important;
    }

    label {
      color: $gris-50;
    }

    span {
      background: $disabled-bg;
      border-color: $gris-50;
      color: $gris-50;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;

      &::before {
        color: $gris-50 !important;
      }
      &:hover {
        border: 2px solid $gris-50;
        box-shadow: -0.1rem 0.2rem 0.1rem rgba(49, 112, 143, 0.3);
        -webkit-box-shadow: -0.1rem 0.2rem 0.1rem rgba(49, 112, 143, 0.3);
        -moz-box-shadow: -0.1rem 0.2rem 0.1rem rgba(49, 112, 143, 0.3);
      }
      &:active {
        border: 2px solid $gris-50 !important;
        background-color: $disabled-bg;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
      }
    }
  }
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: 'Subir archivo' !important;
}
.bnt-hide {
  display: block;
  height: 32px;
}
.header-table-custom {
  background: $blue-conacyt;
  color: $blanco;
  border-radius: 24px 24px 0 0;
  height: 45px;
  padding: 15px;
}

.custom-dropdown-item {
  position: relative; /* Necesario para posicionar los pseudoelementos correctamente */
}

.custom-dropdown-item::before {
  background: #425668;
  bottom: auto;
  content: '';
  height: 8px;
  left: 23px;
  margin-top: 15px;
  position: absolute;
  right: auto;
  width: 8px;
  z-index: 1;
  border-radius: 50%;
}

.custom-dropdown-item::after {
  content: '';
  border-left: 1px solid #425668;
  bottom: 0;
  content: '';
  left: 27px;
  position: absolute;
  top: 0;
}

.custom-dropdown-item:last-child::after {
  bottom: 50%;
}
