





























































































































































































































































































































.toggled {
  #collapseLenguage {
    display: none !important;
  }

  .logo .logo-img {
    width: 25px;
  }
}

.user-info {
  display: flex;
  padding: 5px 4px;
  align-items: center;

  .user-title-avatar {
    margin-left: 15px;
  }

  .b-avatar-sm {
    margin: 0 0.6rem 0.6rem 0.6rem;
  }
}

.navbar-brand.logo {
  padding: 5px 12px;
}

.logo .logo-img {
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
}

.logo-img {
  height: 100%;
  background-size: contain;
  width: 100%;
}

.navbar-title {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: middle;
}

.navbar-version {
  font-size: 10px;
  color: #ccc;
}

.content-user-modal {
  display: flex;

  p {
    :first-child {
      font-size: 60px;
      line-height: 50px;
      font-weight: 300;
      color: #002663;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
  }
}

#sidebar-wrapper {
  .sidebar_grouper {
    padding: 10px 20px !important;
  }
}

ul.list-unstyled.subitems {
  border-right: 6px solid #00ba2a;
  padding-left: 36px;
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .navbar-title {
    font-size: 0.875rem;
  }

  .logo .logo-img {
    height: 50px;
    background-size: contain;
    width: 29px;
  }
}

//menor a 768px sidebar
@media screen and (max-width: 768px) {
  .custom-dropdown {
    margin-left: 0px !important;
  }
}
