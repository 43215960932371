








































.is-invalid ~ .invalid-feedback {
  display: inline-block !important;
}
.invalid-feedback {
  width: 50% !important;
}
