



















































































.body {
  display: flex;
  place-items: center;
  font-family: 'Montserrat', sans-serif;
  margin: 0 auto;

  h3,
  h5 {
    font-size: 14px;
  }

  a {
    font-size: 16px;
    color: #001a44;
  }

  .container-f {
    display: flex;
    margin: auto auto 0 auto;
    position: fixed;
    padding: 0%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    z-index: 2;
    width: 100%;
  }

  .btn {
    padding: 0.5rem 0.5rem;
    border: none;
    outline: none;
    background-color: #e6e6e6;
    color: #494949;
    width: 100%;
  }

  .item-text {
    font-size: 14px;
    padding: 0;
  }

  .drop-wrapper {
    position: relative;
    display: flex;
    width: 35%;
  }

  .drop-menu {
    display: none;
    position: absolute;
    width: 100%;
    align-items: center;
    align-content: center;
    margin: 0 auto;
  }

  .drop-menu.active {
    display: flex;
    text-align: center;
  }

  .drop-menu.active a {
    width: auto;
    text-align: left;
  }

  .dm-2 {
    align-items: center;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 3px 0 #ccc;
    padding: 30px;
    border-radius: 20px 20px 0 0;
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 65px;
    left: 0px;
    margin: 100% auto 0;

    ul {
      margin-bottom: 0px !important;
    }
  }

  .dropup {
    bottom: 100%;
    width: auto;
  }

  .drop-menu a {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #343434;
    border-bottom: 1px solid #001a441c;
  }

  .btn h5 {
    margin: 0;
    color: #838383;
  }

  .titulo-drop {
    display: block;
    text-align: initial;
    padding: 0 0 20px 0;
    color: #494949;
  }

  .titulo-drop img {
    margin-right: 10px;
  }

  .drop-wrapper .btn:hover {
    background-color: #001a44;
    border-bottom: 2px solid rgb(63, 244, 17) !important;
    color: white;
  }

  .drop-menu h3 {
    font-size: 16px;
    font-weight: bold;
    color: #858585;
    text-align: left;
  }

  .btn h5:focus {
    color: white;
  }

  .fnp-1 {
    margin: 20px 0 10px 0;
  }

  #icon-person-2 {
    font-size: 20px;
    color: #494949;
    margin-right: 10px;
  }

  .centrate {
    width: auto;
    margin: 0 auto;
    text-align: center;
    display: block;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 564px) {
  .body {
    .container-f {
      padding: 0%;
    }

    h3,
    h5 {
      font-size: 12px;
    }

    .btn {
      padding: 0.5rem 0rem;
      display: block;
    }

    .btn-1 {
      padding: 0.5rem 0rem;
      display: block;
    }

    .drop-menu.active a {
      display: block;
      text-align: left;
    }

    .item-text {
      font-size: 12px;
    }
  }
}

@media (max-width: 338px) {
  .body {
    .item-text {
      font-size: 10px;
    }
  }
}

@media (min-width: 1000px) {
  .body {
    display: none;
  }
}
